import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Banner extends Component {

  _scrollTo = (element) => {
    document.querySelector(element).scrollIntoView({ 
      behavior: 'smooth' 
    })
  }

  render() {

    let props = this.props

    return (
      <section className='banner' style={{backgroundImage: `url(${props.background_image && props.background_image.localFile.childImageSharp.original.src})`}}>
        <div className='banner__inner'>
          <div className='banner__content'>
            <Fade bottom distance='30px'>
              <h1>{props.title}</h1>
              <p>{props.subtitle}</p>
              { props.button && props.button.text &&
                <div className='btn' onClick={() => this._scrollTo('.lead-form__right')}>{props.button.text}</div>
              }
            </Fade>
          </div>
        </div>
        { props.cta &&
          <div className='banner__cta'>
            <div className='banner__inner'>
              <p dangerouslySetInnerHTML={{ __html: props.cta }} />
            </div>
          </div>
        }
      </section>
    )

  }

}

export default Banner