import React from 'react'
import Fade from 'react-reveal/Fade'

const Timeline = (props) => (
  <section className='timeline'>
    <div className='timeline__inner'>
      <Fade bottom distance='30px'>
        { props.title && <h3>{props.title}</h3> }
        { props.subtitle && <p className='subtitle'>{props.subtitle}</p> }
      </Fade>
      { props.timeline &&
        <div className='timeline__wrapper'>
          { props.timeline.map((el, i) => {
            return ( 
              <div className='timeline__item' key={i}>
                <span className='timeline__item-number'><a href={el.hotdoc} data-hotdoc-widget="lightbox">Book Now</a></span>
                <Fade cascade bottom distance='30px'>
                  <img className='timeline__item-image' src={el.image.localFile.childImageSharp.original.src} alt={el.name} />
                  <div className='timeline__item-content'>
                    <div>
                      <h4>{el.name}</h4>
                      <p className='position'>{el.position}</p>
                      <p className='content'>{el.content}</p>
                      <a className='mobile-link' href={el.hotdoc} target='_blank' rel='noopener noreferrer'>Book Now</a>
                    </div>
                  </div>
                </Fade>
              </div>
            )
          }) }
        </div>
      }
    </div>
  </section>
)

export default Timeline