import React from 'react'

import { Lightning, Alarm, Car, Tools, Days, Team, Tick, Performance, Pathway, OneYearIntensive, FeeHelp, Download, TheatreMask, Ambulance, Stopwatch, Heartbeat, HandSparkle, Skating } from '../components/icons'

const RenderIcon = (param) => {
  let icon = {
    'Lightning': () => (<Lightning />),
    'Alarm': () => (<Alarm />),
    'Car': () => (<Car />),
    'Tools': () => (<Tools />),
    'Days': () => (<Days />),
    'Team': () => (<Team />),
    'Tick': () => (<Tick />),
    'Pathway': () => (<Pathway />),
    'Performance': () => (<Performance />),
    'OneYearIntensive': () => (<OneYearIntensive />),
    'FeeHelp': () => (<FeeHelp />),
    'Download': () => (<Download />),
    'TheatreMask': () => (<TheatreMask />),
    'Stopwatch': () => (<Stopwatch />),
    'HandSparkle': () => (<HandSparkle />),
    'Skating': () => (<Skating />),
    'Heartbeat': () => (<Heartbeat />),
    'Ambulance': () => (<Ambulance />),
  }[param]

  if (!icon) return

  return icon()
}

export default RenderIcon