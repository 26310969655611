import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class SimplePricing extends Component {

  render() {
    const { title, pricing } = this.props
    return (
      <section className='pricing'>
        <Fade bottom distance='30px'>
          <div className='pricing__inner'>
            <h3>{ title }</h3>
            { pricing.length &&
              <ul>
                { pricing.map((item, i) => {
                  return ( 
                    <li className='pricing-table__value' key={i}>
                      <a href="https://paradise-physio.au3.cliniko.com/bookings" data-hotdoc-widget="lightbox">
                        <span className='title'>{item.title}</span>
                        <span className='price'>{item.price}</span>
                      </a>
                    </li>
                  )
                }) }
              </ul>
            }
          </div>
        </Fade>
      </section>
    )
  }
}

export default SimplePricing