import React, { Component } from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

import markerIcon from '../assets/images/marker.svg'

class Map extends Component {

  render() {

    let { title, content, google_map } = this.props

    const location = { lat: parseFloat(google_map.lat), lng: parseFloat(google_map.lng) };

    const MapWithAMarker = withScriptjs(withGoogleMap(props =>
      <GoogleMap
        defaultZoom={12}
        defaultCenter={location}
        options={{ 
          mapTypeControl: false,
          fullscreenControl: false,
          rotateControl: false,
          clickableIcons: true,
          scrollwheel:false,
        }}
      >
        <Marker
          position={location}
          icon={{ url: markerIcon, size: {width: 64, height: 84}, anchor: {x:32 , y: 42}, scaledSize: {width: 32, height: 42} }}
        />
      </GoogleMap>
    ));

    return (
      <div className='map'>
        <h3>{title}</h3>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <div className='map__wrapper'>
          <MapWithAMarker
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD5J9vhQ91dZUQtVSvuawhiKYqSP6FoQxU&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} className={'map__map'} />}
          />
        </div>
      </div>
    );
  }
}

export default Map