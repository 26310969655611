import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import left from '../assets/images/left-arrow.svg'
import right from '../assets/images/right-arrow.svg'

// Component to fix beforeChange bug rerendering slick
class BlockUpdate extends Component {
  shouldComponentUpdate() {
    return false
  }
  render() {
    return (<>{ this.props.children }</>)
  }
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={right}
      alt='next gallery item'
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={left}
      alt='last gallery item'
      onClick={onClick}
    />
  );
}

class Carousel extends Component {

  state = {
    activeSlide: 0,
  }

  render() {
    const props = this.props
    const settings = {
      infinite: false,
      speed: 600,
      fade:true,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      cssEase: 'ease',
      pauseOnHover: false,
      focusOnSelect: false,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    }
    return (
      <section className='carousel'>
        <Fade cascade bottom distance='30px'>
        <div className='carousel__inner'>
          { props.carousel &&
            <BlockUpdate>
              <Slider className="carousel__gallery" {...settings}>
                { props.carousel.map((el, i) => {
                  return ( 
                    <div className='carousel__item' key={i}>
                      { el.title &&
                        <h2>{ el.title }</h2>
                      }
                      { el.subtitle &&
                        <p>{ el.subtitle }</p>
                      }
                      <img src={el.image.localFile.childImageSharp.original.src} alt={el.image.alt_text} />
                    </div>
                  )
                }) }
              </Slider>
            </BlockUpdate>
          }
        </div>
        </Fade>
      </section>
    )
  }
}

export default Carousel