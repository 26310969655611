import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import left from '../assets/images/left-arrow.svg'
import right from '../assets/images/right-arrow.svg'

// Component to fix beforeChange bug rerendering slick
class BlockUpdate extends Component {
  shouldComponentUpdate() {
    return false
  }
  render() {
    return (<>{ this.props.children }</>)
  }
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={right}
      alt='next gallery item'
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={left}
      alt='last gallery item'
      onClick={onClick}
    />
  );
}

class Items extends Component {

  state = {
    activeSlide: 0,
  }

  render() {
    const props = this.props
    const settings = {
      infinite: true,
      speed: 600,
      autoplay: false,
      slidesToShow: 6,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      cssEase: 'ease',
      centerMode: true,
      pauseOnHover: false,
      focusOnSelect: false,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    }
    return (
      <section className='items'>
        <Fade bottom distance='30px'>
          { props.title &&
            <h2>{ props.title }</h2>
          }
          { props.subtitle &&
            <p>{ props.subtitle }</p>
          }
          { props.items &&
            <BlockUpdate>
              <Slider className="items__items" {...settings}>
                { props.items.map((el, i) => {
                  return ( 
                    <div className='items__item' key={i}>
                      <img src={el.image.localFile.childImageSharp.original.src} alt={el.alt_text} />
                      <h4>{el.title}</h4>
                    </div>
                  )
                }) }
              </Slider>
            </BlockUpdate>
          }
        </Fade>
      </section>
    )
  }
}

export default Items