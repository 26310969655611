import React, { Component } from 'react'
import { navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'

import RenderIcon from '../utils/render-icon'
//import Recaptcha from 'react-google-recaptcha'

//const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY
//const RECAPTCHA_KEY = '6Leseq4ZAAAAAF_A_DZp3gHnPKNqrbkRnu3fdjCW'
//const RECAPTCHA_KEY = '6Leseq4ZAAAAAGTUNSisMDWaw8N2Uc9sNJ8Q72bb'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class LeadForm extends Component {

  state = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    message: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  // handleRecaptcha = value => {
  //   this.setState({ "g-recaptcha-response": value });
  // }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/?no-cache=1", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
    .then(() => {
      navigate('/contact-thank-you')
    })
    .catch(error => this.setState({result: 'fail', msg: error}));
  }

  render() {
    const props = this.props
    return (
      <section className='lead-form'>
        <div className='lead-form__left'>
          <div className='lead-form__content'>
            <Fade bottom distance='30px'>
              { props.title &&
                <h2>{ props.title }</h2>
              }
              { props.content &&
                <div dangerouslySetInnerHTML={{ __html:props.content }} />
              }
            </Fade>
            <a href="https://paradise-physio.au3.cliniko.com/bookings" title="Book medical appointments with James de Leijer" target="_blank" className="btn">Make a Booking</a>
          </div>

        </div>
        <div className='lead-form__right'>
            <div className='lead-form__form'>
              <h3>{ props.form.title }</h3>
              <p>{ props.form.subtitle }</p>
              <form
                className='form'
                name="lead_form"
                data-netlify='true'
                id='free-quote'
                method='post'
                onSubmit={this.handleSubmit}
              >
                <input type='hidden' name='form-name' value='lead_form' />
                <div className='form__row form__row--double'>
                  <input type='text' name='first_name' id='first_name' placeholder='First Name*' onChange={this.handleChange} required />
                  <input type='text' name='last_name' id='last_name' placeholder='Last Name*' onChange={this.handleChange} required />
                </div>
                <div className='form__row'>
                  <input type='email' name='email' id='email' onChange={this.handleChange} placeholder='Email Address*' required />
                </div>
                <div className='form__row'>
                  <input type='text' name='phone_number' id='phone_number' onChange={this.handleChange} placeholder='Phone Number*' required />
                </div>
                <div className='form__row'>
                  <textarea name='message' id='message' onChange={this.handleChange} placeholder='Message*' required />
                </div>
                <div className='form__row form__row--submit'>
                  <button className='btn' type='submit'>Submit</button>
                </div>
              </form>
            </div>
        </div>
      </section>
    )
  }
}

export default LeadForm
