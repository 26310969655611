import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class ThankYou extends Component {

  render() {

    let props = this.props

    return (
      <section className='banner banner--thanks' style={{backgroundImage: `url(${props.background_image.localFile.childImageSharp.original.src})`}}>
        <div className='banner__inner'>
          <div className='banner__content'>
            <Fade bottom distance='30px'>
              <h2>{props.title}</h2>
              <p>{props.subtitle}</p>
              { props.download_link &&
                <a href={props.download_link} className='btn' target='_blank' rel='noopener noreferrer'>Download Brochure</a>
              }
            </Fade>
          </div>
        </div>
      </section>
    )

  }

}

export default ThankYou