import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import RenderIcon from '../utils/render-icon'

class IconsRow extends Component {
  render() {
    const props = this.props
    return (
      <section className='icons-row'>
        <Fade cascade bottom distance='30px'>
        <div className='icons-row__inner'>
          { props.items.map((el, i) => {
            return ( 
              <div className='icons-row__item' key={i}>
                { RenderIcon(el.icon) }
                <h4>{el.title}</h4>
              </div>
            )
          }) }
        </div>
        </Fade>
      </section>
    )
  }
}

export default IconsRow