import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Testimonials extends Component {
  render() {
    const props = this.props
    return (
      <section className='testimonials'>
        <Fade cascade bottom distance='30px'>
        <div className='testimonials__inner'>
          { props.title &&
            <h3>{ props.title }</h3>
          }
          { props.subtitle &&
            <p>{ props.subtitle }</p>
          }
          { props.testimonials &&
            <div className='testimonials__items'>
              { props.testimonials.map((el, i) => {
                return ( 
                  <div className='testimonials__item' key={i}>
                    <h4>{el.quote}</h4>
                    <p>{el.name}</p>
                  </div>
                )
              }) }
            </div>
          }
        </div>
        </Fade>
      </section>
    )
  }
}

export default Testimonials