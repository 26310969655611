import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import left from '../assets/images/left-arrow.svg'
import right from '../assets/images/right-arrow.svg'

// Component to fix beforeChange bug rerendering slick
class BlockUpdate extends Component {
  shouldComponentUpdate() {
    return false
  }
  render() {
    return (<>{ this.props.children }</>)
  }
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={right}
      alt='next gallery item'
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={left}
      alt='last gallery item'
      onClick={onClick}
    />
  );
}

class Carousel extends Component {

  state = {
    activeSlide: 0,
  }

  render() {
    const { title, logos } = this.props
    const settings = {
      infinite: true,
      speed: 600,
      autoplay: false,
      slidesToShow: 8,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      cssEase: 'ease',
      centerMode: true,
      pauseOnHover: false,
      focusOnSelect: false,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    }
    return (
      <section className='partners'>
        <Fade bottom distance='30px'>
          <h4>{title}</h4>
          { logos &&
            <BlockUpdate>
              <Slider className="parterns__items" {...settings}>
                { logos.map((el, i) => {
                  return ( 
                    <div className='partners__item' key={i}>
                      <img src={el.icon.localFile.childImageSharp.original.src} alt={el.icon.alt_text} />
                    </div>
                  )
                }) }
              </Slider>
            </BlockUpdate>
          }
        </Fade>
      </section>
    )
  }
}

export default Carousel