import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class ImageTitleContent extends Component {

  render() {
    const { image, title, content } = this.props
    return (
      <section className='image-title-content'>
        <Fade bottom distance='30px'>
          <div className='image-title-content__inner'>
            <div className='image-title-content__title'>
              { title && <h2>{title}</h2> }
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div className='image-title-content__image'>
              { image && image.localFile && image.localFile.childImageSharp.original.src && <img src={image.localFile.childImageSharp.original.src} alt={title} /> }
            </div>
          </div>
        </Fade>
      </section>
    )
  }
}

export default ImageTitleContent