import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class MoreInformation extends Component {

  render() {
    const { title, info } = this.props
    return (
      <section className='pricing pricing--more more-info'>
        <Fade bottom distance='30px'>
          <div className='pricing__inner'>
            <h3>{ title }</h3>
            { info.length &&
              <ul>
                { info.map((item, i) => {
                  return ( 
                    <li className='pricing-table__value' key={i}>
                      <a href={item.link.url.source_url} target="_blank" rel="noopener noreferrer">
                        <span className='title'>{item.title}</span>
                        <span className='price'>{item.subtitle}</span>
                      </a>
                    </li>
                  )
                }) }
              </ul>
            }
          </div>
        </Fade>
      </section>
    )
  }
}

export default MoreInformation