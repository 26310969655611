import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import Tick from '../assets/images/tick.svg'
import Cross from '../assets/images/cross.svg'

class PricingTable extends Component {
  _scrollTo = (e, element) => {
    e.preventDefault()
    document.querySelector(element).scrollIntoView({ 
      behavior: 'smooth' 
    })
  }
  render() {
    const props = this.props
    return (
      <section className='pricing-table'>
        <Fade cascade bottom distance='30px'>
        <div className='pricing-table__inner'>
          { props.options &&
            <div className='pricing-table__table'>
              <div className='pricing-table__item'>
                <ul>
                  <li className='pricing-table__header'></li>
                  <li className='pricing-table__pricing'></li>
                  { props.options[0].items.map((item, i) => {
                    return ( 
                      <li className='pricing-table__value' key={i}>
                        <span className='title'>{item.title}</span>
                        <span className='subtitle'>{item.subtitle}</span>
                      </li>
                    )
                  }) }
                </ul>
              </div>
              { props.options.map((el, i) => {
                return ( 
                  <div className='pricing-table__item' key={i}>
                    <ul>
                      <li className='pricing-table__header'>
                        <h3>{el.title}</h3>
                      </li>
                      <li className='pricing-table__pricing'>
                        <h4>{el.price}</h4>
                        <span>{el.subtitle}</span>
                      </li>
                      { el.items.map((item, i) => {
                        return ( 
                          <li className={item.faded ? 'pricing-table__value faded' : 'pricing-table__value'} key={i}>
                            <span className='pricing-table__mobile'>
                              <span className='title'>{item.title}</span>
                              <span className='subtitle'>{item.subtitle}</span>
                            </span>
                            { item.tick &&
                              <img src={Tick} alt='Yes' />
                            }
                            { !item.tick &&
                              <img src={Cross} alt='No' />
                            }
                          </li>
                        )
                      }) }
                      <li className='pricing-table__button'><a className='btn' href='#free-quote' onClick={(e) => this._scrollTo(e, '.lead-form')}>Get a Free Quote</a></li>
                    </ul>
                  </div>
                )
              }) }
            </div>
          }
        </div>
        </Fade>
      </section>
    )
  }
}

export default PricingTable